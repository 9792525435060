import { render, staticRenderFns } from "./MyDesigns.vue?vue&type=template&id=287f07cf&scoped=true&"
import script from "./MyDesigns.vue?vue&type=script&lang=js&"
export * from "./MyDesigns.vue?vue&type=script&lang=js&"
import style0 from "./MyDesigns.vue?vue&type=style&index=0&id=287f07cf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "287f07cf",
  null
  
)

export default component.exports