<template>
  <div class="wrap1">
      <div class="myArticles">
          <div class="pt-15 pb-5 d-flex jc-between ai-end" style="border-bottom:1px solid #999; color:#333;">
              <div class="d-flex ai-end">
                  <span class="fs-18 px-5"><strong>{{ articleTitle }}</strong></span>
                  <router-link v-if="articleList.length && articleTitle == '我的文章'" class="m-c-p ml-10 fs-15" to="/publishArticle" tag="span" style="color: #1e8870">发布新文章</router-link>
              </div>
              <span v-if="articleList.length > 4" class="fs-13 m-c-p" style="color:#1e8870;" @click="readMore(articleTitle)">查看更多...</span>
          </div>
          <div class="m-20" v-if="!articleList.length">
              <div class="d-flex jc-center fs-15" style="margin-top: 4rem; margin-left:-13rem">
                  <span style="color: grey">{{ noArticle }}<router-link v-if="noArticle == '暂无文章'" class="m-c-p ml-10" to="/publishArticle" tag="span" style="color: #1e8870">点击发布</router-link></span>
              </div>
          </div>
          <div v-else class="m-20">
              <article-title v-for="item in articleLists" :key="item._id" :article="item" :border="true" :edit="articleTitle == '我的文章' ? true : false"></article-title>
          </div>
      </div>
      <div class="myDesigns">
          <div class="pt-15 pb-5 d-flex jc-between ai-end" style="border-bottom:1px solid #999; color:#333;">
              <div class="d-flex ai-end">
                  <span class="fs-18 px-5"><strong>{{ designTitle }}</strong></span>
                  <router-link v-if="designList.length && designTitle == '我的设计'" class="m-c-p ml-10 fs-15" to="/publishDesign" tag="span" style="color: #1e8870">发布新设计</router-link>
              </div>
              <span v-if="designList.length > 8" class="fs-13 m-c-p" style="color:#1e8870;"  @click="readMore(designTitle)">查看更多...</span>
          </div>
          <div class="m-20" v-if="!designList.length">
              <div class="d-flex jc-center fs-15" style="margin-top: 4rem; margin-left:-13rem">
                  <span style="color: grey">{{ noDesign }}<router-link v-if="noDesign == '暂无设计'" class="m-c-p ml-10" to="/publishArticle" tag="span" style="color: #1e8870">点击发布</router-link> </span>
              </div>
          </div>
          <div v-else class="m-20">
              <div class="d-flex flex-wrap">
                  <design-card v-for="item in designLists" :key="item._id" :designContent="item" :width="200" :edit="designTitle == '我的设计' ? true : false"></design-card>
              </div>
          </div>
      </div>
      <div class=""></div>
  </div>
</template>

<script>
import ArticleTitle from '../components/ArticleTitle';
import DesignCard from '../components/designCard.vue'
export default {
    props:['userId', 'show', 'flagFalsh'],
    data(){
        return {
            articleList: [],
            designList: [],
            articleTitle: '我的文章',
            designTitle:'我的设计',
            noArticle: '暂无文章',
            noDesign: '暂无设计'
        }
    },
    methods:{
        //  获取文章
        async getArticleList(userId, sort){
            const model = await this.$axios.get(`/rest/myArticleList/${userId}/${sort}`)
            if(model.data.length > 0){
                this.articleList = model.data.reverse()
            }else{
                this.articleList = []
                sort == '我的收藏' && (this.noArticle = '暂无收藏')
                sort == '我的文章' && (this.noArticle = '暂无文章')
                sort == '关注文章' && (this.noArticle = '暂无关注')
            }
        },
        //  获取设计
        async getDesignList(userId, sort){
            const model = await this.$axios.get(`/rest/myDesignList/${userId}/${sort}`)
            if(model.data.length > 0){
                model.data.map((item, index) => model.data[index].userId = { username: this.$store.state.user.username})
                this.designList = model.data.reverse()
            }else{
                this.designList = []
                sort == '设计收藏' && (this.noDesign = '暂无收藏')
                sort == '我的设计' && (this.noDesign = '暂无设计')
                sort == '关注设计' && (this.noDesign = '暂无关注')
            }
        },
        contentInfo(value){
            if(value == "文章"){
                this.articleTitle = '我的文章'
                this.designTitle = '我的设计'
                this.getArticleList(this.userId, '我的文章')
                this.getDesignList(this.userId, '我的设计')
            }else if(value == "收藏"){
                this.articleTitle = '我的收藏'
                this.designTitle = '设计收藏'
                this.getArticleList(this.userId, '我的收藏')
                this.getDesignList(this.userId, '设计收藏')
            }else if(value == "关注"){
                this.articleTitle = '我关注用户的文章更新'
                this.designTitle = '我关注用户的设计更新'
                this.getArticleList(this.userId, '关注文章')
                this.getDesignList(this.userId, '关注设计')
            }
        },
        readMore(sort){
            this.$emit('changeContent', sort)
        }
    },
    computed:{
        designLists(){
            if(this.designList.length > 8){
                return this.designList.slice(0, 8)
            }else{
                return this.designList
            }
        },
        articleLists(){
            if(this.articleList.length > 4){
                return this.articleList.slice(0, 4)
            }else{
                return this.articleList
            }
        }
    },
    components:{
        ArticleTitle,
        DesignCard
    },
    watch:{
        userId(value){
            this.getArticleList(value,'我的文章')
            this.getDesignList(value, '我的设计')
        },
        show(value){
            this.contentInfo(value)
        },
        flagFalsh(){
            this.contentInfo(this.show)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap1{
    background: white;
    border-radius: 0.5rem;
    padding: 0 3rem 3rem 3rem;
}
</style>