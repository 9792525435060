<template>
  <div class="wrap1">
      <div class="myArticles">
          <div class="pt-15 pb-5 d-flex jc-between ai-end" style="border-bottom:1px solid #999; color:#333;">
              <div class="d-flex ai-end">
                  <span class="fs-18 px-5"><strong>{{ title }}</strong></span>
                  <router-link v-if="title == '我的文章'" class="m-c-p ml-10 fs-15" to="/publishArticle" tag="span" style="color: #1e8870">发布新文章</router-link>
              </div>
          </div>
          <div class="m-20">
              <article-title v-for="item in articles" :key="item._id" :article="item" :border="true" :edit="title == '我的文章' ? true : false"></article-title>
          </div>
      </div>
  </div>
</template>

<script>
import ArticleTitle from '../components/ArticleTitle';
export default {
    props:['articles', 'title'],
    data(){
        return {}
    },
    components:{
        ArticleTitle
    }
}
</script>

<style lang="scss" scoped>
.wrap1{
    background: white;
    border-radius: 0.5rem;
    padding: 0 3rem 3rem 3rem;
}
</style>