<template>
  <div class="background-color">
      <el-scrollbar style="height:100%">
        <div style="min-height:calc(100vh - 26rem); ">
            <div class="mb-30" style="margin-left: -10vw;">
                <div class="info-background d-flex flex-column ai-center">
                    <img src="../../../images/avatarbackground.jpg" alt="">
                    <div class="mask"></div>
                </div>
                <div class="wrap d-flex flex-column ai-center">
                    <div class="avatar-wrap d-flex jc-center">
                        <div class="avatar d-flex ai-center jc-center">
                            <div class="img-wrap">
                                <img :src="$store.state.user.touxiang" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="fs-18 mt-15"><strong>{{ $store.state.user.username }}</strong></div>
                </div>
                <div class="content d-flex">
                <div class="nav-wrap fs-15 px-30 mr-20" style="flex-shrink: 0; align-self: flex-start;">
                    <p class="m-c-p" @click="toNav('文章')" :class="{active: isActive == '文章'}"> <span class="iconfont icon-19"></span> 我的文章</p>
                    <p class="m-c-p" @click="toNav('收藏')" :class="{active: isActive == '收藏'}"> <span class="iconfont icon-shoucang1"></span> 我的收藏</p>
                    <p class="m-c-p" @click="toNav('关注')" :class="{active: isActive == '关注'}"> <span class="iconfont icon-yonghu"></span> 关注用户</p>
                    <!-- <p class="m-c-p" @click="toNav('消息')" :class="{active: isActive == '消息'}"> <span class="iconfont icon-xiaoxi"></span> 我的消息</p> -->
                    <p class="m-c-p" @click="changeTX"> <span class="iconfont icon-touxiang"></span> 修改头像</p>
                </div>
                <!-- 文章相关 -->
                <div v-if="!message" class="list-wrap flex-grow-1">
                    <personal-articles v-if="more == 'default'" :userId = userInfo._id :show="isActive" @changeContent = changeContent></personal-articles>
                    <component v-else :is="more" :title="title" :articles="articles"></component>
                </div>
                <!-- 消息 -->
                <div v-else>
                    <message-com></message-com>
                </div>
            </div>
        </div>

        <!-- 修改头像 -->
        <div class="change-touxiang d-flex jc-center ai-center" v-if="changeTouxiang"  @click.self="changeTouxiang = false">
            <div class="touxiang-wrap d-flex flex-column jc-between ai-center">
                <div class="d-flex flex-wrap p-30">
                    <div class="m-10" v-for="(item, index) in touxiangList" :key="item" style="position:relative;width: 10rem; height: 10rem; overflow:hidden; border-radius:50%;">
                        <img :src="item" alt="" class="touxiang" @click="chooseTX(item, index)">
                        <div v-if="touxiangIndex == index" style="position:absolute;width:100%; height: 100%; top:0; left:0;background:rgba(0,0,0,0.5)" class="d-flex jc-center ai-center">
                            <span class="iconfont icon-xuanzhong" style="font-size: 3rem; color: white"></span>
                        </div>
                    </div>
                </div>
                <div class="mb-30">
                    <button class="touxiang-button fs-18 m-c-p" @click="okTouxiang">确认</button>
                </div>
            </div>
        </div>
        </div>
        <div>
            <footer-com :color="true"></footer-com>
        </div>
      </el-scrollbar>
  </div>
</template>

<script>
import PersonalArticles from '../components/PersonalArticles';
import MyArticles from '../components/MyArticles';
import MyDesigns from '../components/MyDesigns';
import FooterCom from '../components/Footer';
import MessageCom from '../components/MessageCom';
export default {
    data(){
        return {
            userInfo: '',
            changeTouxiang: false,
            touxiangList: [],
            touxiangIndex: -1,
            touxiangUrl: '',
            isActive: '文章',
            more: 'default',
            title: '',
            articles: [],
            message: false
        }
    },
    components:{
        PersonalArticles,
        MyArticles,
        MyDesigns,
        FooterCom,
        MessageCom
    },
    methods:{
        async getUserInfo(){
            const result = await this.$axios.get('/user')
            this.userInfo = result.data
        },
        async changeTX(){
            this.changeTouxiang = !this.changeTouxiang
            const result = await this.$axios.get('/rest/touxiang')
            this.touxiangList = result.data
        },
        chooseTX(url, index){
            this.touxiangIndex = index
            this.touxiangUrl = url
        },
        async okTouxiang(){
            if(this.touxiangUrl){
                const result = await this.$axios.post(`/changeTouxiang/${this.$store.state.user._id}`, {touxiang: this.touxiangUrl})
                if(result.data.message == 'success'){
                    this.$store.commit('changeTouxiang', this.touxiangUrl)
                }
            }
            this.changeTouxiang = !this.changeTouxiang
        },
        toNav(com){
            if(com == '消息'){
                this.message = true
            }else{
                this.message = false
                this.more = 'default'
                this.isActive = '123'
            }
            setTimeout(() => {
                this.isActive = com
            }, 0);
        },
        changeContent(value){
            this.isActive = ''
            switch(value){
                case '我的文章':
                    this.more = MyArticles
                    this.title = '我的文章'
                    this.$axios.get(`/rest/myArticleList/${this.$store.state.user._id}/我的文章`).then(result => this.articles = result.data.reverse())
                    break
                case '我的设计':
                    this.more = MyDesigns
                    this.title = '我的设计'
                    this.$axios.get(`/rest/myDesignList/${this.$store.state.user._id}/我的设计`).then(result => this.articles = result.data.reverse())
                    break
                case '我的收藏':
                    this.more = MyArticles
                    this.title = '我的收藏'
                    this.$axios.get(`/rest/myArticleList/${this.$store.state.user._id}/我的收藏`).then(result => this.articles = result.data.reverse())
                    break
                case '设计收藏':
                    this.more = MyDesigns
                    this.title = '设计收藏'
                    this.$axios.get(`/rest/myDesignList/${this.$store.state.user._id}/设计收藏`).then(result => this.articles = result.data.reverse())
                    break
                case '我关注用户的文章更新':
                    this.more = MyArticles
                    this.title = '我关注用户的文章更新'
                    this.$axios.get(`/rest/myArticleList/${this.$store.state.user._id}/关注文章`).then(result => this.articles = result.data.reverse())
                    break
                case '我关注用户的设计更新':
                    this.more = MyDesigns
                    this.title = '我关注用户的设计更新'
                    this.$axios.get(`/rest/myDesignList/${this.$store.state.user._id}/关注设计`).then(result => this.articles = result.data.reverse())
                    break
            }
        }
    },
    mounted(){
        if(!localStorage.token){
            return this.$router.push('/')
        }
        this.getUserInfo()
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
    .background-color{
        background: map-get($colors, 'background');
        height: 102vh;
        .info-background{
            margin-top: 5rem;
            height: 35rem;
            overflow: hidden;
            .mask{
                width: 110vw;
                height: 20rem;
                margin-top: -20rem;
                background-image: linear-gradient(transparent, #111);
            }
        }
        .wrap{
            margin: 0 auto;
            width: 140rem;
            .avatar-wrap{
                margin-top: -8rem;
                height: 16rem;
                .avatar{
                    width: 16rem;
                    height: 16rem;
                    border-radius: 50%;
                    background-color: white;
                    .img-wrap{
                        width: 14.5rem;
                        height: 14.5rem;
                        border-radius: 50%;
                        background-color: grey;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        .content{
            width: 140rem;
            margin: 0 auto;
            margin-top: 5rem;
            .nav-wrap{
                background-color: white;
                width: 20rem;
                border-radius: 0.5rem;
                .active{
                    font-weight: bold;
                }
            }
        }

        .change-touxiang{
            position: fixed;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0,0,0,0.3);
            .touxiang-wrap{
                width: 54rem;
                height: 50rem;
                border-radius: 0.5rem;
                box-shadow: 0 0 3rem rgba(0,0,0,0.5);
                background: white;
                .touxiang{
                    width: 100%;
                    position: relative;
                }
                .touxiang-button{
                    background: map-get($colors, 'purple' );
                    border:none;
                    color: white;
                    width: 10rem;
                    height: 4rem;
                    border-radius: 0.5rem;
                }
            }
        }
    }
</style>