<template>
  <div class="message-wrap">
    1231
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.message-wrap{
    height: 100rem;
    background: white;
}
</style>